import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SearchProvider } from './search/SearchProvider';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import About from './pages/About';
import Home from './pages/Home';
import WellnessMetropolis from './pages/OnlineClasses';
import EmotionsFalls from './pages/EmotionsFalls';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import CommunityChallenges from './pages/CommunityChallenges';
import ChallengeDetail from './pages/ChallengeDetail';
import ClassroomTools from './pages/ClassroomTools';
import LavaIsland from './pages/LavaIsland';
import Activity1 from './pages/lava-island/activity1';
import Activity2 from './pages/lava-island/activity2';
import TempActivity1 from './pages/lava-island/tempactivity1'
import TempActivity2 from './pages/lava-island/tempactivity2'

function App() {
  return (
    <AuthProvider>
      <SearchProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/community-challenges" element={<CommunityChallenges />} />
            <Route path="/challenge/:month" element={<ChallengeDetail />} />
            <Route path="/online-classes" element={<WellnessMetropolis />} />

            {/* Emotions Falls protected route */}
            <Route 
              path="/emotions-falls" 
              element={
                <ProtectedRoute moduleId="emotions-falls">
                  <EmotionsFalls />
                </ProtectedRoute>
              } 
            />

            {/* Lava Island protected routes */}
            <Route 
              path="/lava-island" 
              element={
                <ProtectedRoute moduleId="lava-island">
                  <LavaIsland />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/lava-island/activity1" 
              element={
                <ProtectedRoute moduleId="lava-island">
                  <Activity1 />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/lava-island/activity2" 
              element={
                <ProtectedRoute moduleId="lava-island">
                  <Activity2 />
                </ProtectedRoute>
              } 
            />

            {/* Lava Island unprotected routes */}
            <Route path="/activity-1" element={<TempActivity1 />} />
            <Route path="/activity-2" element={<TempActivity2 />} />

            <Route path="/classroom-tools" element={<ClassroomTools />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
          </Routes>
        </Router>
      </SearchProvider>
    </AuthProvider>
  );
}

export default App;