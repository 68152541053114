import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

const CommunityChallenges = () => {
  const [activeFilters, setActiveFilters] = useState(['All']);

  const filters = [
    'SEL Basics', 'Self-Management', 'Social Awareness', 
    'Relationship Skills', 'Decision-Making', 'Self-Compassion',
    'Empathy & Compassion', 'Positive Communication',
    'Teamwork & Cooperation', 'SEL Event Leadership'
  ];

  const challenges = [
    {
      month: 'september',
      title: '"All About Me" Wall',
      description: 'Students make posters about their interests, strengths, and emotions.',
      category: 'SEL Basics',
      image: '/images/communitychallenges/1.jpg'
    },
    {
      month: 'october',
      title: 'Classroom Calm-Down Kit Creation',
      description: 'Students create their own calm-down kits using items like stress balls, coloring pages, and relaxation cards and place a station in each classroom. Students can also bring items from home to keep in their classroom to help them calm down.',
      category: 'Self-Management',
      image: '/images/communitychallenges/2.jpg'
    },
    {
      month: 'november',
      title: 'Diversity Blanket',
      description: 'Students create a square that represents symbols that define who they are. Do a gallery walk where students come to view each others.',
      category: 'Social Awareness',
      image: '/images/communitychallenges/3.jpg'
    },
    {
      month: 'december',
      title: 'Letter To A Friend',
      description: 'Students will pick a name out of a hat and write a message to the person they picked about what makes that person special. Prior to writing the letter they should spend time with each other.',
      category: 'Relationship Skills',
      image: '/images/communitychallenges/4.jpg'
    },
    {
      month: 'january',
      title: 'My Goals Mind Map Wall',
      description: 'Students set personal and academic goals for the new year and create a wall with their maps showing how they plan to achieve it.',
      category: 'Decision-Making',
      image: '/images/communitychallenges/5.jpg'
    },
    {
      month: 'february',
      title: 'Gratitude Journals',
      description: 'Have students keep daily gratitude journals, writing about things they are thankful for.',
      category: 'Self-Compassion',
      image: '/images/communitychallenges/6.jpg'
    },
    {
      month: 'march',
      title: 'Acts of Kindness Wall',
      description: 'Students plan and execute small acts of kindness within the classroom or school. Showcase these acts through illustration.',
      category: 'Empathy & Compassion',
      image: '/images/communitychallenges/7.jpg'
    },
    {
      month: 'april',
      title: 'Emotions Wall',
      description: 'Students pick a card or drawing and describe a time they felt that way, encouraging them to communicate their emotions effectively. Students will illustrate and write a description for this emotion.',
      category: 'Positive Communication',
      image: '/images/communitychallenges/8.jpg'
    },
    {
      month: 'may',
      title: 'Team Challenges',
      description: 'Organize classroom challenges that require students to work together, like building the tallest tower with limited materials.',
      category: 'Teamwork & Cooperation',
      image: '/images/communitychallenges/9.jpg'
    },
    {
      month: 'june',
      title: 'SEL Activities Festival',
      description: 'Student youth leaders will plan and conduct an activities fair that implements creative activities that help support their ideas around SEL.',
      category: 'SEL Event Leadership',
      image: '/images/communitychallenges/10.jpg'
    }
  ];

  const toggleFilter = (filter) => {
    setActiveFilters(prevFilters => {
      if (filter === 'All') return ['All'];
      const newFilters = prevFilters.includes(filter)
        ? prevFilters.filter(f => f !== filter)
        : [...prevFilters.filter(f => f !== 'All'), filter];
      return newFilters.length === 0 ? ['All'] : newFilters;
    });
  };

  const filteredChallenges = challenges.filter(challenge =>
    activeFilters.includes('All') || activeFilters.includes(challenge.category)
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-orange-50 via-yellow-50 to-teal-50">
      <Header />
      <NavBar />
      <main className="max-w-7xl mx-auto px-4 py-20">
        <div className="bg-white rounded-3xl shadow-lg p-8 mb-12">
          <h1 className="text-4xl font-bold text-teal-600 mb-4 text-center tracking-wide">
            SEL Community Bucket List Challenges 2024-2025
          </h1>
          <p className="text-gray-600 leading-relaxed text-lg text-center max-w-4xl mx-auto">
            Welcome to the SEL Community Bucket List Challenges for the 2024-2025 school year! This bucket list is packed with exciting events and activities designed to promote social-emotional learning (SEL) and foster a strong sense of community at your school.
            By participating in these challenges, students, teachers, and staff will have the opportunity to build meaningful connections, develop essential SEL skills, and create a positive and inclusive school environment.
          </p>
          <p className="text-teal-600 font-medium text-lg text-center mt-6">
            Let's work together to make this school year unforgettable by embracing the power of SEL and community spirit!
          </p>
        </div>

        <div className="bg-white rounded-3xl shadow-lg p-8">
          <div className="flex gap-4 mb-8 flex-wrap">
            {filters.map(filter => (
              <button
                key={filter}
                onClick={() => toggleFilter(filter)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 hover:transform hover:-translate-y-1 ${
                  activeFilters.includes(filter) 
                    ? 'bg-teal-400 text-white shadow-md' 
                    : 'bg-white text-teal-600 border border-teal-400 hover:bg-teal-50'
                }`}
              >
                {filter}
              </button>
            ))}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredChallenges.map((challenge, index) => (
              <Link 
                to={`/challenge/${challenge.month}`}
                key={index}
                className="bg-white rounded-2xl shadow-md overflow-hidden hover:shadow-xl transition-all duration-300 hover:-translate-y-1 border border-gray-100"
              >
                <div className="h-48 bg-gray-200 relative">
                  <img
                    src={challenge.image}
                    alt={`${challenge.month} challenge`}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-4 left-4 bg-white/90 px-3 py-1 rounded-full text-sm text-teal-600 font-medium capitalize">
                    {challenge.month}
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-teal-600 mb-3">{challenge.title}</h3>
                  <p className="text-gray-600 mb-4">{challenge.description}</p>
                  <span className="inline-block px-3 py-1 rounded-full text-sm bg-teal-50 text-teal-600 border border-teal-100">
                    {challenge.category}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default CommunityChallenges;