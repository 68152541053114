import Fuse from "fuse.js";
import { searchableData } from "../data/searchableData";

// Fuse.js options for fuzzy search
const options = {
  keys: ["title", "description"], // Fields to search
  threshold: 0.5,                // Fuzzy match threshold (lower is stricter)
  includeScore: true,            // Include match scores
  shouldSort: true,              // Sort by relevance
};

// Initialize Fuse with the searchable data
const fuse = new Fuse(searchableData, options);

// Perform search and return results
export const performSearch = (query) => {
  if (!query) return [];
  const results = fuse.search(query);
  return results.map((result) => result.item); // Extract matched items
};