import React, { useState, useRef, useEffect } from 'react';
import Header from '../../components/Header';
import NavBar from '../../components/Navbar';
import Footer from '../../components/Footer';

const Activity2 = () => {
  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [currentEmotion, setCurrentEmotion] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768); // Check initial screen size
  const wheelRef = useRef(null);

  const emotions = [
    { name: 'JOY', color: '#FFEB3B', bgColor: 'rgba(255, 235, 59, 0.2)' },
    { name: 'SADNESS', color: '#2196F3', bgColor: 'rgba(33, 150, 243, 0.2)' },
    { name: 'FEAR', color: '#9C27B0', bgColor: 'rgba(156, 39, 176, 0.2)' },
    { name: 'DISGUST', color: '#4CAF50', bgColor: 'rgba(76, 175, 80, 0.2)' },
    { name: 'ANGER', color: '#f44336', bgColor: 'rgba(244, 67, 54, 0.2)' },
    { name: 'EMBARRASSMENT', color: '#E91E63', bgColor: 'rgba(233, 30, 99, 0.2)' },
    { name: 'ENVY', color: '#009688', bgColor: 'rgba(0, 150, 136, 0.2)' },
    { name: 'ANXIETY', color: '#FF9800', bgColor: 'rgba(255, 152, 0, 0.2)' },
  ];

  // Update screen size state on resize
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getPieSegmentPath = (startAngle, endAngle, radius) => {
    const start = polarToCartesian(radius, startAngle);
    const end = polarToCartesian(radius, endAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return [
      'M',
      200,
      200,
      'L',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      1,
      end.x,
      end.y,
      'L',
      200,
      200,
    ].join(' ');
  };

  const polarToCartesian = (radius, angle) => {
    const angleInRadians = ((angle - 90) * Math.PI) / 180.0;
    return {
      x: 200 + radius * Math.cos(angleInRadians),
      y: 200 + radius * Math.sin(angleInRadians),
    };
  };

  const spinWheel = () => {
    if (!isSpinning) {
      setIsSpinning(true);
      const newRotation = rotation + 2000 + Math.random() * 3000;
      setRotation(newRotation);

      setTimeout(() => {
        const normalizedRotation = (newRotation % 360 + 360) % 360;
        const adjustedRotation = (360 - normalizedRotation + 180) % 360;
        const sectionSize = 360 / emotions.length;
        let landedIndex = Math.floor(adjustedRotation / sectionSize) % emotions.length;
        landedIndex = (landedIndex - 1 + emotions.length) % emotions.length;

        setCurrentEmotion(emotions[landedIndex].name);
        setIsSpinning(false);
      }, 5000);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-purple-100 via-blue-50 to-pink-100">
      <Header />
      <NavBar />
        <main className="flex-1 container mx-auto px-4 py-8">
            <div className="text-center mb-8">
            <h1 className="text-5xl font-bold text-purple-600 mb-4">The Emotion Wheel! 🎡</h1>
            <p className="text-xl text-gray-600">
                Spin the wheel to discover and learn about different emotions! ⭐
            </p>
            </div>

            <div className="max-w-3xl mx-auto bg-white rounded-3xl shadow-xl p-8">
            <div className="relative aspect-square">
                <div className="absolute inset-0 rounded-full border-4 border-gray-200 bg-white"></div>
                <div className="absolute top-1/2 -right-6 -translate-y-1/2 transform z-20">
                <div className="w-8 h-8">
                    <svg viewBox="0 0 24 24" className="w-full h-full">
                    <path
                        d="M5 12L15 4L15 20L5 12Z"
                        fill="#EC4899"
                        stroke="#BE185D"
                        strokeWidth="2"
                    />
                    </svg>
                </div>
                </div>

                <div
                ref={wheelRef}
                className="absolute inset-0 transition-transform duration-[5000ms] ease-out"
                style={{ transform: `rotate(${rotation}deg)` }}
                >
                <svg viewBox="0 0 400 400" className="absolute inset-0">
                    {emotions.map((emotion, index) => {
                    const sectionSize = 360 / emotions.length;
                    const startAngle = index * sectionSize - 90;
                    const endAngle = (index + 1) * sectionSize - 90;

                    return (
                        <path
                        key={`segment-${emotion.name}`}
                        d={getPieSegmentPath(startAngle, endAngle, 200)}
                        fill={emotion.bgColor}
                        stroke={emotion.color}
                        strokeWidth="2"
                        />
                    );
                    })}
                </svg>

                {emotions.map((emotion, index) => {
                    const angle = (index * 360) / emotions.length;
                    const rotateAngle = angle + rotation;

                    return (
                    <div
                        key={emotion.name}
                        className="absolute w-full h-full"
                        style={{
                        transform: `rotate(${angle - 22.5}deg)`,
                        transformOrigin: 'center center',
                        }}
                    >
                        <div
                        className="absolute top-0 left-1/2"
                        style={{
                            transform: `
                            translateX(-50%)
                            translateY(32px)
                            rotate(${-(rotateAngle - 22.5)}deg)
                            `,
                        }}
                        >
                        <div
                            style={{
                            width: isSmallScreen ? '48px' : '112px',
                            height: isSmallScreen ? '48px' : '112px',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            overflow: 'hidden',
                            }}
                        >
                            <img
                            src={`/images/emotions/${emotion.name.toLowerCase()}.png`}
                            alt={emotion.name}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: 'top',
                            }}
                            />
                        </div>
                        <div
                            className="absolute w-full text-center mt-2"
                            style={{
                            fontSize: isSmallScreen ? '10px' : '14px',
                            fontWeight: 'bold',
                            color: emotion.color,
                            textShadow:
                                '1px 1px 2px white, -1px -1px 2px white, 1px -1px 2px white, -1px 1px 2px white',
                            }}
                        >
                            {emotion.name}
                        </div>
                        </div>
                    </div>
                    );
                })}
                </div>

                <button
                onClick={spinWheel}
                disabled={isSpinning}
                className={`
                    absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                    w-20 h-20 rounded-full
                    bg-gradient-to-r from-purple-500 to-pink-500
                    text-white font-bold text-xl
                    shadow-lg z-10
                    transform transition-all duration-200
                    ${isSpinning ? 'opacity-50' : 'hover:scale-105 active:scale-95'}
                `}
                >
                {isSpinning ? 'SPINNING' : 'SPIN!'}
                </button>
            </div>

            <div className="mt-12 p-6 bg-white rounded-xl border-2 border-purple-200">
                <div className="space-y-4">
                <div className="flex items-baseline gap-2">
                    <p className="text-lg text-gray-700">
                    What emotion did you get on the emotions wheel?
                    </p>
                    <span className="inline-block min-w-[200px] px-4 py-2 bg-gray-100 rounded border-b-2 border-purple-300 text-lg font-medium text-purple-600">
                    {currentEmotion}
                    </span>
                </div>

                <div className="space-y-2">
                    <p className="text-lg text-gray-700">
                    Write about a time you felt that emotion. How did you manage that emotion?
                    </p>
                    <textarea
                    className="w-full h-32 p-4 border-2 border-purple-200 rounded-lg focus:border-purple-400 focus:ring focus:ring-purple-200 focus:ring-opacity-50 resize-none"
                    placeholder="Write your response here..."
                    />
                </div>
                </div>
            </div>

            <div className="text-center mt-8">
                <p className="text-gray-600 text-lg">
                Click the big purple button to spin the wheel!
                </p>
                <p className="text-purple-500 mt-2">Where will it land? 🎯</p>
            </div>
            </div>
        </main>
      <Footer />
    </div>
  );
};

export default Activity2;