import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

const LavaIsland = () => {
  const navigate = useNavigate();
  
  const modules = [
    {
      image: '/images/emotionfalls/whatissel.gif',
      title: 'INTRODUCTION TO SELF MANAGEMENT:',
      description: "What are my Big Feelingss?",
      due: 'December Activity 1',
      path: '/lava-island/activity1'
    },
    {
      image: '/images/emotionfalls/emotionwheels.gif',
      title: 'EMOTIONS WHEEL:',
      description: 'Identifying and discussing different emotions.',
      due: 'December Activity 2',
      path: '/lava-island/activity2'
    },
  ];

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="flex flex-col min-h-screen w-full bg-gradient-to-b from-red-500 to-orange-500">
      <Header />
      <NavBar />
      <main
        className="flex-1 relative overflow-hidden w-full bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/images/lavaisland/test.png')" }}
      >
        {/* Flame (Right) and Cliff (Left) Images */}
        <div className="absolute top-20 left-8 z-10 w-72 md:w-80 lg:w-96 animate-float-slow">
          <img
            src="/images/lavaisland/island.png"
            alt="Lava Island Cliff"
            className="w-full h-auto"
          />
        </div>
        <div className="absolute top-28 right-8 z-20 w-64 h-64 md:w-72 md:h-72 animate-bounce-slow">
          <img
            src="/images/onlineclasses/flame.gif"
            alt="Flame Character"
            className="w-full h-full"
          />
        </div>

        {/* Content Overlay */}
        <div className="relative pt-48 pb-16 flex flex-col items-center px-4">
          <div className="bg-white/10 backdrop-blur-md p-8 rounded-3xl max-w-4xl w-full text-center shadow-xl border border-white/20">
            <h1 className="text-5xl md:text-6xl font-bold text-white tracking-wider mb-6 animate-fade-in">
              WELCOME TO<br />LAVA ISLAND
            </h1>
            <h2 className="text-2xl md:text-4xl text-white font-bold mb-4">
              THINKING ABOUT YOUR EMOTIONS
            </h2>
            <p className="text-lg md:text-2xl text-orange-100 mb-8">Module 2 | December</p>

            <div className="space-y-6 bg-black/20 p-6 rounded-2xl">
              <p className="text-orange-50 text-lg leading-relaxed">
                Goal: Students will learn strategies for managing their emotions and behaviors.
              </p>
              <p className="text-orange-50 text-lg leading-relaxed">
                At the end of the lesson, make sure to download your certificate. You'll find the download button at the end of the
                training. Completing these lessons is important, and it will help you become a SEL EMPOWER Leader by the end of the
                school year. We're excited to see you learn and grow!
              </p>
            </div>
          </div>

          {/* Modules Section */}
          <div className="flex flex-col md:flex-row items-center justify-center gap-16 px-4 mt-16">
            {modules.map((module, index) => (
              <div 
                key={index} 
                className="bg-white/10 backdrop-blur-sm p-8 rounded-3xl flex flex-col items-center text-center transform hover:scale-105 transition-all duration-300 border border-white/20"
              >
                <img
                  src={module.image}
                  alt={module.title}
                  className="w-64 h-64 mb-6 rounded-2xl"
                />
                <h3 className="text-white text-lg md:text-xl font-semibold uppercase mb-3">
                  {module.title}
                </h3>
                <p className="text-orange-100 text-md md:text-lg mb-2">
                  {module.description}
                </p>
                <p className="text-orange-200 text-md md:text-lg mb-4">
                  {module.due}
                </p>
                <button 
                  onClick={() => handleNavigate(module.path)}
                  className="bg-gradient-to-r from-orange-400 to-red-400 text-white px-10 py-3 rounded-full text-md md:text-lg hover:from-orange-500 hover:to-red-500 transition-all duration-300 transform hover:-translate-y-1 shadow-lg hover:shadow-xl font-bold"
                >
                  Begin Adventure
                </button>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />

      <style jsx>{`
        @keyframes float-slow {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-20px); }
        }
        
        @keyframes bounce-slow {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-15px); }
        }

        @keyframes fade-in {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }

        .animate-float-slow {
          animation: float-slow 6s ease-in-out infinite;
        }

        .animate-bounce-slow {
          animation: bounce-slow 4s ease-in-out infinite;
        }

        .animate-fade-in {
          animation: fade-in 1s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default LavaIsland;