// Home.js YouthSELEmpower.org

import React from 'react';
import Header from '../components/Header';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

const Home = () => {
  const services = [
    {
      title: 'Online SEL Training Courses',
      description: 'Our Online SEL Training Courses for Kids are interactive, engaging, and designed to help children develop crucial Social Emotional Learning (SEL) skills. These courses cover a wide range of topics, including understanding and managing emotions, building healthy relationships, setting and achieving positive goals, and making responsible decisions.',
      color: 'bg-yellow-100'
    },
    {
      title: 'SEL Academic Integration Tools',
      description: 'Our SEL Academic Integration Tools are a suite of resources designed to seamlessly incorporate Social Emotional Learning into academic curricula. These tools include lesson plans, activity guides, and assessment instruments tailored to various grade levels and subject areas. By integrating SEL with academic content, educators can create a holistic learning experience that promotes students\' emotional and cognitive growth.',
      color: 'bg-blue-100'
    },
    {
      title: 'Youth Leader Mentorship',
      description: 'The Youth Leader Mentorship program is an empowering initiative that pairs experienced youth leaders with younger students to develop leadership skills, self-confidence, and a sense of responsibility. Through this mentorship program, youth leaders receive training and support to guide their mentees in developing SEL competencies such as self-awareness, social skills, and responsible decision-making. The program includes regular mentorship sessions, leadership workshops, and community service projects that encourage mentees to apply their learning in real-world contexts.',
      color: 'bg-green-100'
    }
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-orange-50 via-yellow-50 to-teal-50">
      <Header />
      <NavBar />
      <main className="flex-grow">
        {/* Hero Section */}
        <div className="container mx-auto px-4 py-16">
          <div className="bg-white rounded-3xl shadow-lg p-8 mb-12">
            <h1 className="text-4xl md:text-5xl font-bold text-teal-600 mb-8 tracking-wide text-center">
              SEL | Academic Integration & Youth Leadership Empower
            </h1>
            <div className="relative overflow-hidden rounded-2xl">
              <img 
                src="/images/home/banner1.gif" 
                alt="Youth empowerment illustration with emotion icons" 
                className="w-full max-w-3xl mx-auto"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-white/20 to-transparent" />
            </div>
            <p className="mt-10 text-gray-600 max-w-4xl mx-auto text-lg leading-relaxed text-center">
              Welcome to the Youth SEL EMPOWER initiative, a dynamic program dedicated to cultivating leadership in elementary schools throughout New York City Public Schools. 
              Through climate and culture events, this initiative champions social-emotional learning, fostering a strong sense of belonging and meaningful connections among peers. 
              Guided by high school youth leaders known as Heart Buddies, the program bridges elementary and secondary education, paving the way for mentorship and personal growth.
            </p>
          </div>
        </div>

        {/* Services Section */}
        <section className="py-16 px-4">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center mb-12 text-teal-600">
              Services
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              {services.map((service) => (
                <div 
                  key={service.title} 
                  className={`${service.color} rounded-2xl p-8 shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1`}
                >
                  <h3 className="text-xl font-semibold text-center text-teal-600 mb-4">
                    {service.title}
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Home;