// src/components/LoginModal.js
import React from 'react';

const LoginModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
        
        <h2 className="text-2xl font-bold mb-6 text-center">Log In</h2>
        
        <div className="space-y-4">
          <div>
            <p className="text-center text-sm mb-4 font-medium font-nunito">
              New to this site? <a href="#" className="text-purple-600 hover:underline">Sign Up</a>
            </p>
          </div>
          
          <div>
            <input
              type="email"
              placeholder="Email"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-500 text-sm font-nunito"
            />
          </div>
          
          <div>
            <input
              type="password"
              placeholder="Password"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-500 text-sm font-nunito"
            />
          </div>
          
          <div>
            <a href="#" className="text-sm text-purple-600 hover:underline font-nunito">
              Forgot password?
            </a>
          </div>
          
          <button className="w-full bg-purple-600 text-white py-2 rounded-md hover:bg-purple-700 transition-colors font-medium text-base">
            Log In
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;