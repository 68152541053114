export const searchableData = [
    {
      type: "Page",
      title: "Home",
      description: "Welcome to the SEL Empower Program landing page.",
      path: "/",
    },
    {
      type: "Page",
      title: "About",
      description: "Learn about our mission, vision, and goals.",
      path: "/about",
    },
    {
      type: "Page",
      title: "Community Challenges",
      description: "Explore SEL activities and challenges for schools.",
      path: "/community-challenges",
    },
    {
      type: "Page",
      title: "Classroom Tools",
      description: "Access resources to support social-emotional learning.",
      path: "/classroom-tools",
    },
    {
      type: "Page",
      title: "Contact",
      description: "Get in touch with us for more information.",
      path: "/contact",
    },
    {
      type: "Page",
      title: "Online Classes",
      description: "Learn about our SEL training courses and classes.",
      path: "/online-classes",
    },
    {
      type: "Page",
      title: "Blog",
      description: "Read our latest updates and articles.",
      path: "/blog",
    },
];  