import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';

const GuestModal = ({ isOpen, onClose }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    const success = login(password);
    if (success) {
      setPassword('');
      setError('');
      onClose();
    } else {
      setError('Incorrect password');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center backdrop-blur-sm">
      <div className="bg-gradient-to-b from-orange-50 to-teal-50 rounded-3xl p-8 max-w-md w-full mx-4 relative shadow-2xl border-2 border-teal-100">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 bg-white rounded-full w-8 h-8 flex items-center justify-center text-teal-500 hover:text-teal-600 hover:bg-teal-50 transition-colors shadow-md"
        >
          ✕
        </button>
        
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold mb-4 text-teal-600">
            Welcome Back! 👋
          </h2>
          <p className="text-gray-600 font-medium text-lg leading-relaxed">
            Enter the magic password to start your adventure!
          </p>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="bg-white rounded-2xl p-6 shadow-inner">
            <input
              type="password"
              placeholder="Enter your secret password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-3 border-2 border-teal-200 rounded-xl focus:outline-none focus:border-teal-400 text-center text-lg placeholder-gray-400 transition-colors"
            />
            {error && (
              <p className="text-red-500 text-sm text-center mt-3 bg-red-50 py-2 rounded-lg">
                Oops! That's not the right password. Try again! 🔄
              </p>
            )}
          </div>
          
          <button 
            type="submit"
            className="w-full bg-teal-500 text-white py-4 rounded-xl font-bold text-lg hover:bg-teal-600 transition-all duration-200 transform hover:-translate-y-1 shadow-lg hover:shadow-xl flex items-center justify-center gap-2"
          >
            <span>Let's Go!</span> 🚀
          </button>
        </form>
      </div>
    </div>
  );
};

export default GuestModal;