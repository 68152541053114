import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

// Challenges Data (move this to a shared file if needed)
const challenges = [
  {
    month: 'september',
    title: 'September: "All About Me" Wall',
    category: 'SEL Basics',
    image: '/images/communitychallenges/1.jpg',
    objective:
      'Students will understand the concept of self-awareness and create a visual representation of their interests, strengths, and emotions. At the end of the lesson, students will be able to: Demonstrate self-awareness by identifying and illustrating their own interests, strengths, and emotions. Enhance their emotional literacy by discussing and sharing personal traits with peers. Improve their presentation skills by creating and explaining their "All About Me" posters.',
    materials: [
      'Poster boards or large sheets of paper',
      'Markers, crayons, and colored pencils',
      'Magazines and newspapers for cutouts',
      'Glue sticks and scissors',
      'Photos or printouts of personal interests',
      'Stickers and embellishments',
    ],
    lessonPlan: [
      {
        title: 'Introduction (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Welcome students and introduce the concept of Social Emotional Learning (SEL) and self-awareness. Discuss why understanding one’s own emotions, strengths, and interests is important for personal growth and relationships.</p>
            <p><strong>Discussion Points:</strong></p>
            <ul className="list-disc ml-5">
              <li>What is SEL?</li>
              <li>Why is self-awareness important?</li>
              <li>Examples of self-awareness in everyday life.</li>
            </ul>
          </>
        ),
      },
      {
        title: 'Brainstorming (10 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Guide students to think about their interests, strengths, and emotions. Encourage them to jot down ideas and discuss them with a partner.</p>
            <p><strong>Activity:</strong> Pair students and have them share three things they are interested in, three strengths, and three emotions they often feel.</p>
          </>
        ),
      },
      {
        title: 'Creating Posters (20 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Distribute materials and explain how to create an "All About Me" poster. Encourage creativity and self-expression.</p>
            <p><strong>Activity:</strong> Students use markers, crayons, photos, magazine cutouts, and other materials to create their posters. They should include their name, favorite hobbies, talents, and emotions.</p>
          </>
        ),
      },
      {
        title: 'Gallery Walk (10 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Organize a gallery walk where students can display their posters on the wall.</p>
            <p><strong>Activity:</strong> Students walk around and view each other’s posters, making notes of interesting things they learned about their classmates.</p>
            <p><strong>Discussion Points:</strong></p>
            <ul className="list-disc ml-5">
              <li>What did you learn about your classmates?</li>
              <li>How do you feel about sharing your own poster?</li>
            </ul>
          </>
        ),
      },
    ],
    assessment: [
      'Observe student participation in brainstorming and poster creation.',
      'Evaluate the creativity and effort put into the posters.',
      'Note students’ engagement during the gallery walk and discussion.',
    ],
  },
  {
    month: 'october',
    title: 'October: Classroom Calm-Down Kit Creation',
    category: 'Self-Management',
    image: '/images/communitychallenges/2.jpg',
    objective:
      'Students will learn self-management skills by creating their own calm-down kits to help manage their emotions. At the end of the lesson, students will be able to: Demonstrate self-management by identifying and creating tools to help manage their emotions. Improve their stress management skills by choosing and using appropriate calming items. Enhance their creative thinking and problem-solving abilities through the design and assembly of their calm-down kits.',
    materials: [
      'Small boxes or containers (shoe boxes, plastic bins, etc.)',
      'Stress balls, fidget spinners, and other tactile items',
      'Coloring pages and crayons',
      'Relaxation cards with breathing exercises or positive affirmations',
      'Personal items from home (optional)',
      'Stickers and markers for decoration',
    ],
    lessonPlan: [
      {
        title: 'Introduction (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Explain self-management and the concept of a calm-down kit. Discuss why it’s important to have strategies to manage emotions.</p>
            <p><strong>Discussion Points:</strong></p>
            <ul className="list-disc ml-5">
              <li>What is self-management?</li>
              <li>Examples of self-management in daily life.</li>
              <li>How can a calm-down kit help?</li>
            </ul>
          </>
        ),
      },
      {
        title: 'Brainstorming (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Lead a discussion on items that can help manage emotions and stay calm. Have students share their ideas and experiences.</p>
            <p><strong>Activity:</strong> Create a list of calming items and strategies suggested by the students.</p>
          </>
        ),
      },
      {
        title: 'Creating Posters (20 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Distribute materials and explain how to create and decorate the calm-down kits. Guide students to include at least three different types of calming items.</p>
            <p><strong>Activity:</strong> Students decorate their containers and fill them with stress balls, coloring pages, relaxation cards, and other calming items. They can also bring personal items from home.</p>
          </>
        ),
      },
      {
        title: 'Gallery Walk (10 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Place a calm-down station in each classroom. Explain how and when to use the station and the kits.</p>
            <p><strong>Activity:</strong> Demonstrate using the calm-down kit and discuss appropriate times to use it.</p>
          </>
        ),
      },
    ],
    assessment: [
      'Observe student participation in brainstorming and kit creation.',
      'Evaluate the thoughtfulness and creativity in decorating and choosing items for the kits.',
      'Note students’ understanding of when and how to use the calm-down kits.',
    ],
  },
  {
    month: 'november',
    title: 'November: Diversity Blanket',
    category: 'Social Awareness',
    image: '/images/communitychallenges/3.jpg',
    objective:
      'Students will develop social awareness by creating squares that represent their unique identities, which will be assembled into a diversity blanket. At the end of the lesson, students will be able to: Demonstrate social awareness by recognizing and appreciating the diversity within their class. Show empathy and cultural competence by creating symbols that represent their unique identities. Enhance their collaborative work skills by contributing to the assembly of the diversity blanket.',
    materials: [
      'Square pieces of fabric or construction paper (8x8 inches)',
      'Markers, crayons, and fabric paint',
      'Needles and thread or glue (for fabric)',
      'Display board or large sheet for the blanket',
      'Scissors and fabric glue',
    ],
    lessonPlan: [
      {
        title: 'Introduction (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Explain social awareness and the value of diversity. Discuss how our differences make us unique and strong.</p>
            <p><strong>Discussion Points:</strong></p>
            <ul className="list-disc ml-5">
              <li>What is social awareness?</li>
              <li>Why is diversity important?</li>
              <li>Examples of diversity in our community.</li>
            </ul>
          </>
        ),
      },
      {
        title: 'Creating Squares (20 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Distribute materials and explain how to create a square that represents their unique identity. Encourage creativity and self-expression.</p>
            <p><strong>Activity:</strong> Students create squares using markers, crayons, and fabric paint, incorporating symbols that represent their culture, interests, and values.</p>
          </>
        ),
      },
      {
        title: 'Assembly (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Guide students in attaching the squares to create a blanket. Sew the squares together if using fabric, or glue them onto a large sheet if using paper.</p>
            <p><strong>Activity:</strong> Students work together to assemble the blanket, helping each other as needed.</p>
          </>
        ),
      },
      {
        title: 'Gallery Walk (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Organize a gallery walk where the completed blanket is displayed.</p>
            <p><strong>Activity:</strong> Students walk around and view each other’s squares, asking questions and making connections with their peers.</p>
            <p><strong>Discussion Points:</strong> What did you learn about your classmates? How do you feel about sharing your own square?</p>
          </>
        ),
      },
    ],
    assessment: [
      'Observe student participation in creating squares and assembling the blanket.',
      'Evaluate the creativity and effort put into the squares.',
      'Note students’ engagement during the gallery walk and discussion.',
    ],
  },
  {
    month: 'december',
    title: 'December: Letter To A Friend',
    category: 'Relationship Skills',
    image: '/images/communitychallenges/4.jpg',
    objective:
      'Students will build relationship skills by writing letters to classmates about what makes them special. At the end of the lesson, students will be able to: Demonstrate relationship skills by learning about and appreciating their classmates. Enhance their communication skills by writing thoughtful and descriptive letters. Show empathy and express appreciation by sharing positive messages with their peers. Your collection is already set up for you with fields and content. Add your own content or import it from a CSV file. Add fields for any type of content you want to display, such as rich text, images, and videos. Be sure to click Sync after making changes in a collection, so visitors can see your newest content on your live site.',
    materials: [
      'Paper and envelopes',
      'Pens and markers',
      'A hat or container for names',
      'Stickers and decorations',
    ],
    lessonPlan: [
      {
        title: 'Introduction (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Explain the importance of relationship skills and the value of expressing appreciation for others.</p>
            <p><strong>Discussion Points:</strong></p>
            <ul className="list-disc ml-5">
              <li>What are relationship skills?</li>
              <li>Why is it important to appreciate others?</li>
              <li>Examples of appreciation in daily life.</li>
            </ul>
          </>
        ),
      },
      {
        title: 'Pairing (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Have students pick a name out of a hat to determine their letter recipient.</p>
            <p><strong>Activity:</strong> Students draw names and sit with their chosen partner.</p>
          </>
        ),
      },
      {
        title: 'Spending Time Together (10 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Allow students to spend time together to learn about each other, sharing interests and positive experiences.</p>
            <p><strong>Activity:</strong> Students ask each other questions about their interests, hobbies, and what they appreciate in a friend.</p>
          </>
        ),
      },
      {
        title: 'Writing Letters (10 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Distribute materials and explain how to write a letter about what makes their partner special.</p>
            <p><strong>Activity:</strong> Students write letters using kind and encouraging words, and decorate them with stickers and drawings.</p>
          </>
        ),
      },
      {
        title: 'Sharing (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Organize a letter exchange where students give their letters to their partners and read them.</p>
            <p><strong>Activity:</strong> Students exchange letters and discuss how it feels to receive and give positive messages.</p>
          </>
        ),
      },
    ],
    assessment: [
      'Observe student participation in pairing and spending time together.',
      'Evaluate the thoughtfulness and effort put into the letters.',
      'Note students’ engagement during the letter exchange and discussion.',
    ],
  },
  {
    month: 'january',
    title: 'January: My Goals Mind Map Wall',
    category: 'Decision-Making',
    image: '/images/communitychallenges/5.jpg',
    objective: 
      'Students will develop responsible decision-making skills by setting personal and academic goals and creating a mind map to achieve them. At the end of the lesson, students will be able to: Demonstrate responsible decision-making by setting and planning to achieve personal and academic goals. Enhance their critical thinking skills by organizing their goals into a visual mind map. Improve their presentation skills by sharing and explaining their goals with the class.Your collection is already set up for you with fields and content. Add your own content or import it from a CSV file. Add fields for any type of content you want to display, such as rich text, images, and videos. Be sure to click Sync after making changes in a collection, so visitors can see your newest content on your live site.',
    materials: [
      'Large sheets of paper or poster boards',
      'Markers, crayons, and colored pencils',
      'Sticky notes',
      'Scissors and glue',
    ],
    lessonPlan: [
      {
        title: 'Introduction (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Discuss responsible decision-making and the importance of setting goals. Explain what a mind map is and how it can help in organizing thoughts.</p>
            <p><strong>Discussion Points:</strong></p>
            <ul className="list-disc ml-5">
              <li>What is responsible decision-making?</li>
              <li>Why is goal-setting important?</li>
              <li>Examples of goals and mind maps.</li>
            </ul>
          </>
        ),
      },
      {
        title: 'Brainstorming (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Guide students to think about their personal and academic goals. Encourage them to discuss their goals with a partner.</p>
            <p><strong>Activity:</strong> Pair students and have them share three personal and three academic goals.</p>
          </>
        ),
      },
      {
        title: 'Creating Mind Maps (20 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Distribute materials and explain how to create a mind map showing their goals and plans to achieve them. Use sticky notes to add steps and resources needed to reach their goals.</p>
            <p><strong>Activity:</strong> Students create mind maps using markers, crayons, and sticky notes, illustrating their goals and the steps to achieve them.</p>
          </>
        ),
      },
      {
        title: 'Wall Display (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Create a wall display with the mind maps. Arrange the maps in a visually appealing way.</p>
            <p><strong>Activity:</strong> Students help arrange the mind maps on the wall and explain their goals to the class.</p>
          </>
        ),
      },
    ],
    assessment: [
      'Observe student participation in brainstorming and creating mind maps.',
      'Evaluate the thoughtfulness and effort put into the mind maps.',
      'Note students’ engagement during the wall display and discussion.',
    ],
  },
  {
    month: 'february',
    title: 'February: Gratitude Journals',
    category: 'Self-Compassion',
    image: '/images/communitychallenges/6.jpg',
    objective:
      'Students will develop self-compassion and resilience by keeping a daily gratitude journal. At the end of the lesson, students will be able to: Demonstrate self-compassion and resilience by regularly practicing gratitude. Improve their writing skills by maintaining a daily gratitude journal. Enhance their reflective thinking by discussing and sharing their gratitude experiences.',
    materials: [
      'Journals or notebooks',
      'Pens and markers',
      'Stickers and decorations',
      'Sample gratitude prompts',
    ],
    lessonPlan: [
      {
        title: 'Introduction (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Explain self-compassion and resilience, and introduce the concept of gratitude. Discuss the benefits of keeping a gratitude journal.</p>
            <p><strong>Discussion Points:</strong></p>
            <ul className="list-disc ml-5">
              <li>What is self-compassion and resilience?</li>
              <li>How does gratitude help build these qualities?</li>
              <li>Examples of gratitude in daily life.</li>
            </ul>
          </>
        ),
      },
      {
        title: 'Creating Journals (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Examples of gratitude in daily life.</p>
            <p><strong>Activity:</strong> Students decorate their journals with stickers and markers.</p>
          </>
        ),
      },
      {
        title: 'Daily Writing (5 minutes/day)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Guide students to write daily entries about things they are thankful for. Encourage them to write at least three things each day.</p>
            <p><strong>Activity:</strong> Students write daily gratitude entries.</p>
          </>
        ),
      },
      {
        title: 'Sharing (5 minutes/week)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Create a supportive environment where students feel comfortable sharing their gratitude.</p>
            <p><strong>Activity:</strong> Allow students to share entries weekly.</p>
          </>
        ),
      },
      {
        title: 'Reflection (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Reflect on how keeping a gratitude journal has helped them. Discuss any changes they have noticed in their attitudes or feelings.</p>
            <p><strong>Activity:</strong> How has gratitude journaling affected you? What have you learned about self-compassion and resilience?</p>
          </>
        ),
      },
    ],
    assessment: [
      'Observe student participation in creating and decorating journals.',
      'Evaluate the consistency and thoughtfulness of daily entries.',
      'Note students’ engagement during weekly sharing and reflection discussions.',
    ],
  },
  {
    month: 'march',
    title: 'March: Acts of Kindness Wall',
    category: 'Empathy & Compassion',
    image: '/images/communitychallenges/7.jpg',
    objective:
      'Students will develop empathy and compassion by planning and executing acts of kindness, and illustrating their experiences. At the end of the lesson, students will be able to: Demonstrate empathy and compassion by planning and performing acts of kindness. Enhance their creative skills by illustrating their experiences with acts of kindness. Improve their reflective thinking by discussing the impact of kindness on themselves and others.',
    materials: [
      'Large poster board or wall space',
      'Markers and stickers',
      'Paper and pens',
      'Art supplies for illustrations',
    ],
    lessonPlan: [
      {
        title: 'Introduction (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Explain empathy and compassion, and discuss the importance of kindness.</p>
            <p><strong>Discussion Points:</strong></p>
            <ul className="list-disc ml-5">
              <li>What is empathy and compassion?</li>
              <li>Why is kindness important?</li>
              <li>Examples of acts of kindness.</li>
            </ul>
          </>
        ),
      },
      {
        title: 'Brainstorming (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Discuss possible acts of kindness. Have students share their ideas and plan small acts they can do within the classroom or school.</p>
            <p><strong>Activity:</strong> Create a list of kind acts suggested by students.</p>
          </>
        ),
      },
      {
        title: 'Planning Acts (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Guide students to create a plan for their acts of kindness, including who, what, when, and how.</p>
            <p><strong>Activity:</strong> Students write down their plans for acts of kindness.</p>
          </>
        ),
      },
      {
        title: 'Execting Acts (1 week)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Encourage students to carry out their planned acts of kindness. Monitor and support as needed.</p>
            <p><strong>Activity:</strong> Students perform their acts of kindness and document their experiences with photos or notes.</p>
          </>
        ),
      },
      {
        title: 'Illustration (15 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Provide art supplies for students to create visual representations of their acts of kindness.</p>
            <p><strong>Activity:</strong> Students illustrate and describe their acts of kindness on the wall.</p>
          </>
        ),
      },
      {
        title: 'Sharing (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Discuss the impact of the acts of kindness. Encourage students to share how it made them feel and how it affected others.</p>
            <p><strong>Activity:</strong> How did your act of kindness impact you and others? What did you learn about empathy and compassion?</p>
          </>
        ),
      },
    ],
    assessment: [
      'Observe student participation in brainstorming and planning acts of kindness.',
      'Evaluate the creativity and effort in illustrations.',
      'Note students’ engagement during discussions about the impact of kindness.',
    ],
  },
  {
    month: 'april',
    title: 'April: Emotions Wall',
    category: 'Positive Communication',
    image: '/images/communitychallenges/8.jpg',
    objective:
      'Students will develop positive communication skills by expressing their emotions through illustrations and descriptions. At the end of the lesson, students will be able to: Demonstrate positive communication by expressing their emotions through writing and illustration. Enhance their emotional literacy by identifying and describing different emotions. Improve their artistic skills by creating visual representations of their emotional experiences.',
    materials: [
      'Cards or small pieces of paper',
      'Markers, crayons, and colored pencils',
      'Display board',
      'Stickers and decorations',
    ],
    lessonPlan: [
      {
        title: 'Introduction (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Explain positive communication and the importance of expressing emotions. Discuss different emotions and how to communicate them effectively.</p>
            <p><strong>Discussion Points:</strong></p>
            <ul className="list-disc ml-5">
              <li>What is positive communication?</li>
              <li>Why is it important to express emotions?</li>
              <li>Examples of expressing emotions effectively.</li>
            </ul>
          </>
        ),
      },
      {
        title: 'Choosing Emotions (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Prepare a set of cards with different emotions. Have students pick cards or drawings representing different emotions.</p>
            <p><strong>Activity:</strong> Students select an emotion card.</p>
          </>
        ),
      },
      {
        title: 'Writing and Illustrating (15 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Distribute materials and explain how to write and illustrate a time they felt the chosen emotion. Encourage detailed and expressive descriptions.</p>
            <p><strong>Activity:</strong> Students write about and illustrate a personal experience related to the chosen emotion.</p>
          </>
        ),
      },
      {
        title: 'Wall Display (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Create an emotions wall with the students’ illustrations and descriptions. Arrange the cards in a visually appealing way.</p>
            <p><strong>Activity:</strong> Students help arrange their work on the wall.</p>
          </>
        ),
      },
      {
        title: 'Sharing (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Discuss the different emotions and experiences, encouraging students to communicate their feelings effectively.</p>
            <p><strong>Activity:</strong> What did you learn from sharing your emotion? How can expressing emotions help in positive communication?</p>
          </>
        ),
      },
    ],
    assessment: [
      'Observe student participation in choosing emotions and creating illustrations.',
      'Evaluate the thoughtfulness and detail in the written and illustrated experiences.',
      'Note students’ engagement during discussions about positive communication.',
    ],
  },
  {
    month: 'may',
    title: 'May: Team Challenges',
    category: 'Teamwork & Cooperation',
    image: '/images/communitychallenges/9.jpg',
    objective:
      'Students will develop teamwork and cooperation skills by participating in team challenges. Assessment: Observe student participation in forming teams and working on challenges. Evaluate the teamwork and cooperation demonstrated during the challenge. Note students’ engagement during the reflection and sharing discussion. At the end of the lesson, students will be able to: Demonstrate teamwork and cooperation by successfully completing team challenges. Improve their problem-solving skills by working together to overcome obstacles. Enhance their communication skills by discussing and reflecting on their team experiences.',
    materials: [
      'Various materials for challenges (e.g., straws, tape, paper, etc.)',
      'Timer',
      'Prizes (optional)',
    ],
    lessonPlan: [
      {
        title: 'Introduction (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Explain the importance of teamwork and cooperation.</p>
            <p><strong>Discussion Points:</strong></p>
            <ul className="list-disc ml-5">
              <li>What is teamwork and cooperation?</li>
              <li>Why are these skills important?</li>
              <li>Examples of teamwork in everyday life.</li>
            </ul>
          </>
        ),
      },
      {
        title: 'Forming Teams (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Divide students into teams.</p>
            <p><strong>Activity:</strong> Assign students to teams of 4-5 members.</p>
          </>
        ),
      },
      {
        title: 'Explaining Challenges (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Explain the team challenges. Provide clear instructions and rules.</p>
            <p><strong>Activity:</strong> Describe the challenge (e.g., building the tallest tower with limited materials).</p>
          </>
        ),
      },
      {
        title: 'Challenge Time (15 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Monitor and support the teams as they work on the challenge. Keep track of time.</p>
            <p><strong>Activity:</strong> Teams work together to complete the challenge within the given time.</p>
          </>
        ),
      },
      {
        title: 'Reflection and Sharing (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Discuss what they learned about teamwork and cooperation. Encourage students to share their experiences.</p>
            <p><strong>Activity:</strong> How did your team work together? What challenges did you face? What did you learn about cooperation?</p>
          </>
        ),
      },
    ],
    assessment: [
      'Observe student participation in forming teams and working on challenges',
      'Evaluate the teamwork and cooperation demonstrated during the challenge.',
      'Note students’ engagement during the reflection and sharing discussion.',
    ],
  },
  {
    month: 'june',
    title: 'June: SEL Activities Festival',
    category: 'SEL Event Leadership',
    image: '/images/communitychallenges/10.jpg',
    objective:
      'Students will celebrate their SEL learning by participating in an activities festival, planned and conducted by student youth leaders. At the end of the lesson, students will be able to: Demonstrate leadership and organizational skills by planning and conducting SEL activities. Celebrate their growth in SEL competencies by participating in various festival activities. Reflect on their SEL journey and share their experiences and learning with peers.',
    materials: [
      'Various materials for activities (craft supplies, sports equipment, etc.)',
      'Tables and chairs for stations',
      'Posters and decorations',
      'Prizes (optional)',
    ],
    lessonPlan: [
      {
        title: 'Introduction (5 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Introduce the festival and its purpose. Celebrate the year’s SEL learning.</p>
            <p><strong>Discussion Points:</strong></p>
            <ul className="list-disc ml-5">
              <li>What is SEL?</li>
              <li>Why are we celebrating?</li>
              <li>Overview of the activities festival.</li>
            </ul>
          </>
        ),
      },
      {
        title: 'Planning (1 week in advance)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Guide student youth leaders in planning and organizing the activities. Provide support and resources as needed.</p>
            <p><strong>Activity:</strong> Student youth leaders brainstorm and plan various activities that support SEL concepts learned throughout the year.</p>
          </>
        ),
      },
      {
        title: 'Setting Up (30 minutes)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Help students set up stations for the activities. Ensure all materials are ready.</p>
            <p><strong>Activity:</strong> Students set up tables, chairs, posters, and decorations for the festival.</p>
          </>
        ),
      },
      {
        title: 'Festival Activities (3 hours)',
        description: (
          <>
            <p><strong>Teacher’s Role:</strong> Monitor and support the activities. Ensure everything runs smoothly and students are engaged.</p>
            <p><strong>Activity:</strong> Students rotate through different activity stations, participating in various SEL-related activities. Possible activities include:</p>
            <ul className="list-disc ml-5">
              <li>Art and craft stations</li>
              <li>Team-building games</li>
              <li>Emotional expression exercises</li>
              <li>Gratitude journaling</li>
              <li>Acts of kindness planning</li>
              <li>Positive communication games</li>
              <li>Soap Making Activities (Stress Relief)</li>
              <li>Slime Making (Stress Relief)</li>
              <li>Friendship Bracelet Making (Peer Relationship Building)</li>
            </ul>
          </>
        ),
      },
    ],
  },
];

const ChallengeDetail = () => {
  const { month } = useParams(); // Get the "month" from the route
  const navigate = useNavigate();

  // Find the challenge data for the given month
  const challenge = challenges.find(challenge => challenge.month === month);

  if (!challenge) {
    return (
      <div className="min-h-screen bg-gray-50 font-nunito">
        <Header />
        <NavBar />
        <main className="max-w-7xl mx-auto px-4 py-20 text-center">
          <h1 className="text-4xl font-bold text-red-600">Challenge Not Found</h1>
          <p className="mt-4 text-gray-700">The challenge you're looking for does not exist.</p>
          <button
            className="mt-6 px-4 py-2 bg-[#4B0081] text-white rounded"
            onClick={() => navigate('/community-challenges')}
          >
            Go Back to Challenges
          </button>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-orange-50 via-yellow-50 to-teal-50">
      <Header />
      <NavBar />
      <main className="max-w-7xl mx-auto px-4 py-10">
        <button
          className="mb-6 px-6 py-2 bg-teal-400 text-white rounded-full shadow-md hover:bg-teal-500 transition-colors flex items-center gap-2"
          onClick={() => navigate('/community-challenges')}
        >
          <span>←</span> Back to Challenges
        </button>
        
        <div className="bg-white rounded-3xl shadow-lg p-8 mb-8">
          <div className="flex flex-col md:flex-row gap-8">
            {challenge.image && (
              <div className="w-full md:w-1/2">
                <img
                  src={challenge.image}
                  alt={challenge.title || "Challenge Image"}
                  className="w-full rounded-2xl shadow-md object-cover"
                />
              </div>
            )}
            <div className="flex flex-col gap-6 text-gray-600 w-full md:w-1/2">
              <h1 className="text-3xl font-bold text-teal-600">{challenge.title}</h1>
              {challenge.objective && (
                <div className="bg-teal-50 rounded-xl p-6">
                  <h2 className="text-xl font-semibold text-teal-600 mb-3">Objective:</h2>
                  <p className="leading-relaxed">{challenge.objective}</p>
                </div>
              )}
              {challenge.materials && challenge.materials.length > 0 && (
                <div className="bg-orange-50 rounded-xl p-6">
                  <h2 className="text-xl font-semibold text-teal-600 mb-3">Materials Needed:</h2>
                  <ul className="list-disc ml-5 space-y-2">
                    {challenge.materials.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-3xl shadow-lg p-8 mb-8">
          {challenge.lessonPlan && challenge.lessonPlan.length > 0 && (
            <>
              <h2 className="text-2xl font-semibold text-teal-600 mb-6">Lesson Plan:</h2>
              <div className="grid gap-6">
                {challenge.lessonPlan.map((step, index) => (
                  <div key={index} className="bg-blue-50 rounded-xl p-6">
                    <h3 className="text-xl font-semibold text-teal-600 mb-3">
                      {index + 1}. {step.title}
                    </h3>
                    <div className="text-gray-600">{step.description}</div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <div className="bg-white rounded-3xl shadow-lg p-8">
          {challenge.assessment && challenge.assessment.length > 0 && (
            <>
              <h2 className="text-2xl font-semibold text-teal-600 mb-6">Assessment:</h2>
              <div className="bg-yellow-50 rounded-xl p-6">
                <ul className="list-disc ml-5 space-y-3">
                  {challenge.assessment.map((item, index) => (
                    <li key={index} className="text-gray-600">{item}</li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ChallengeDetail;