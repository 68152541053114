import React from 'react';
import Header from '../components/Header';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

// BlogPost Component
const BlogPost = ({ title, author, date, readTime, views, comments, likes, image, authorImage, excerpt }) => (
  <div className="bg-white rounded-2xl shadow-lg overflow-hidden mb-8 transform transition-transform duration-300 hover:-translate-y-1">
    {/* Blog Post Image */}
    <div className="relative h-64">
      <img
        src={image}
        alt={title}
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent"></div>
    </div>
    <div className="p-8">
      {/* Author Info Section */}
      <div className="flex items-center mb-4">
        <img src={authorImage} alt={author} className="w-12 h-12 rounded-full mr-4 border-2 border-teal-100" />
        <div>
          <p className="font-medium text-teal-600">{author}</p>
          <div className="flex text-sm text-gray-500">
            <span>{date}</span>
            <span className="mx-1">•</span>
            <span>{readTime} read</span>
          </div>
        </div>
      </div>

      {/* Blog Post Title */}
      <h2 className="text-2xl font-bold text-teal-600 mb-4 hover:text-teal-700 transition-colors">{title}</h2>

      {/* Blog Post Excerpt */}
      <p className="text-gray-600 mb-6 leading-relaxed">{excerpt}</p>

      {/* Stats Section */}
      <div className="flex items-center text-sm text-gray-500 border-t pt-4">
        <span className="flex items-center">
          <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
          </svg>
          {views}
        </span>
        <span className="mx-3">•</span>
        <span className="flex items-center">
          <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
          </svg>
          {comments}
        </span>
        <span className="mx-3">•</span>
        <div className="flex items-center">
          <svg className="w-5 h-5 mr-1 text-red-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
          </svg>
          {likes}
        </div>
      </div>
    </div>
  </div>
);

const Blog = () => {
  // Commented out blogPosts array and rendering logic
  /*
  const blogPosts = [
    {
      title: "SEL EMPOWER KickOff",
      author: "June ArcaMay",
      date: "Oct 29",
      readTime: "3 min",
      views: 24,
      comments: 0,
      likes: 1,
      image: "/images/blog/blog1.gif",
      authorImage: "/images/blog/profile.jpg",
      excerpt: "Building Connection Across Districts! October 28 marked a milestone for our SEL Empower Program as students from more than 12 schools..."
    },
    {
      title: "SEL EMPOWER PROGRAM KICK OFF",
      author: "June ArcaMay",
      date: "Oct 22",
      readTime: "2 min",
      views: 94,
      comments: 0,
      likes: 2,
      image: "/images/blog/blog2.gif",
      authorImage: "/images/blog/profile.jpg",
      excerpt: "We are excited to meet your RSVP'd student leaders on October 28th at 10 AM! Please find below an overview of Monday's SEL Empower Program..."
    }
  ];
  */

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-orange-50 via-yellow-50 to-teal-50">
      <Header />
      <NavBar />
      <main className="flex-grow container mx-auto px-6 py-12">
        {/* Page Header */}
        <div className="bg-white rounded-3xl shadow-lg p-8 mb-12">
          <h1 className="text-4xl font-bold text-teal-600 mb-4 text-center">Our Blog</h1>
          <p className="text-gray-600 text-lg max-w-3xl mx-auto text-center">
            Discover updates, stories, and insights about our Social Emotional Learning (SEL) programs and the positive impact we're making in schools and communities.
          </p>
        </div>

        {/* Blog Posts Grid */}
        {/* 
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {blogPosts.map((post, index) => (
            <BlogPost key={index} {...post} />
          ))}
        </div>
        */}
      </main>
      <Footer />
    </div>
  );
};

export default Blog;