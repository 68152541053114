import React, { useState } from 'react';
import Header from '../components/Header';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

const ToolCard = ({ title, description, image, bgColor }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative h-[400px] overflow-hidden cursor-pointer group rounded-3xl shadow-lg transition-transform duration-300 hover:-translate-y-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`bg-gradient-to-r from-teal-500 to-teal-600 h-full flex`}>
        {/* Content Section */}
        <div className="w-1/2 p-8">
          <div
            className={`transform transition-transform duration-300 ${
              isHovered ? '-translate-y-28 md:-translate-y-48' : 'translate-y-0'
            }`}
          >
            <h3 className="text-white text-2xl font-semibold mb-4">{title}</h3>
            <p className="text-white/90">{description}</p>
            <button
              className={`mt-6 px-6 py-2 border-2 border-white text-white font-medium rounded-full 
                transition-all duration-300 group-hover:opacity-100 opacity-0 hover:bg-white hover:text-teal-600`}
            >
              Read More
            </button>
          </div>
        </div>

        {/* Image Section */}
        <div className="w-1/2 relative">
          <div className="absolute inset-0 bg-gradient-to-l from-transparent to-black/10" />
          <img
            src={`/images/classroomtools/${image}`}
            alt={title}
            className="absolute inset-0 w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

const ClassroomTools = () => {
  const tools = [
    // {
    //   title: "SEL Lessons",
    //   description: "Integrate Social Emotional Learning into your daily teaching with our comprehensive lessons that build emotional intelligence, empathy, and responsible decision-making skills.",
    //   image: "book1.jpg",
    //   bgColor: "bg-[#6B5B95]"
    // },
    {
      title: "Brain Heart Music",
      description: "Discover a unique blend of music designed to stimulate both the brain and the heart. Use these playlists to create a calm, focused, and emotionally balanced atmosphere in your classroom.",
      image: "book2.jpg",
      bgColor: "bg-[#8B4513]"
    },
    {
      title: "Meditation Corner",
      description: "Create a peaceful retreat within your classroom where students can practice mindfulness and meditation, helping them to center themselves and manage stress effectively.",
      image: "book3.gif",
      bgColor: "bg-[#ff8b94]"
    },
    {
      title: "Stress-Free Space",
      description: "Designate a stress-free zone where students can take a moment to breathe, relax, and reset. This area promotes mental wellness and resilience, supporting a healthy, stress-free learning environment.",
      image: "book4.jpg",
      bgColor: "bg-[#2E8B57]"
    },
    // {
    //   title: "Classroom Bookshelf",
    //   description: "Explore our curated bookshelf filled with books that inspire, educate, and support students' emotional and intellectual growth. From stories that teach empathy to guides on mindfulness, our collection has something for every learner.",
    //   image: "book5.jpg",
    //   bgColor: "bg-[#8B4513]"
    // }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-orange-50 via-yellow-50 to-teal-50">
      <Header />
      <NavBar />

      <main className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-3xl shadow-lg p-8 mb-12">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-teal-600 mb-6 tracking-wide text-center">
            Classroom Tools
          </h1>
          <p className="text-gray-600 text-base sm:text-lg leading-relaxed max-w-4xl mx-auto text-center">
            Welcome to our Classroom Tools section, your go-to resource for
            fostering a positive and nurturing learning environment. Here,
            you'll find a suite of thoughtfully curated tools and resources
            designed to support both the academic and emotional well-being of
            your students. Explore the following features:
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {tools.map((tool, index) => (
            <ToolCard key={index} {...tool} />
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ClassroomTools;