import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [unlockedModules, setUnlockedModules] = useState({
    'emotions-falls': false,
    'gentle-cliff': false,
    'lava-island': false,
    'friendship-mountains': false
  });

  const login = (password) => {
    if (password === "heart") {
      setUnlockedModules(prev => ({
        ...prev,
        'emotions-falls': true,
        'lava-island': true // Now also unlock lava-island
      }));
      return true;
    }
    return false;
  };

  const logout = () => {
    setUnlockedModules({
      'emotions-falls': false,
      'gentle-cliff': false,
      'lava-island': false,
      'friendship-mountains': false
    });
  };

  return (
    <AuthContext.Provider value={{ unlockedModules, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};