// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-teal-400 text-white p-4 text-center">
      <p className="text-sm">
        © {new Date().getFullYear()} Youth SEL Empower Initiative. All Rights Reserved.
      </p>
      <nav className="mt-2">
        <ul className="flex justify-center space-x-4 text-xs">
          <li>
            <a href="/privacy-policy" className="hover:underline">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/terms-of-service" className="hover:underline">
              Terms of Service
            </a>
          </li>
          <li>
            <a href="/contact" className="hover:underline">
              Contact Us
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;