import React, { createContext, useState, useContext } from "react";
import { performSearch } from "../utils/fuseSetup";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const handleSearch = (input) => {
    setQuery(input);
    const searchResults = performSearch(input);
    setResults(searchResults);
  };

  // New function to clear the search
  const clearSearch = () => {
    setTimeout(() => {
      setQuery("");
      setResults([]);
    }, 100); // Delay of 100ms
  };

  return (
    <SearchContext.Provider value={{ query, results, handleSearch, clearSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);