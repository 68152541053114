// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Search, UserCircle } from 'lucide-react';
import LoginModal from './LoginModal';
import { useSearch } from '../search/SearchProvider';

const AnimatedLogo = () => {
  const [animate, setAnimate] = useState(true);
  const [bubbleState, setBubbleState] = useState('in');

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(false);
      setBubbleState('out');
      
      setTimeout(() => {
        setAnimate(true);
        setBubbleState('in');
      }, 800);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  // Enhanced styling for each EMPOWER letter
  const letterStyles = [
    'text-[#4CAF50] transform rotate-[-8deg]', // E
    'text-[#FF5252] transform rotate-[8deg]',  // M
    'text-[#2196F3] transform rotate-[-6deg]', // P
    'text-[#FFC107] transform rotate-[7deg]',  // O
    'text-[#9C27B0] transform rotate-[-7deg]', // W
    'text-[#FF4081] transform rotate-[6deg]',  // E
    'text-[#673AB7] transform rotate-[-5deg]'  // R
  ];

  // Split text for character animation
  const youthselChars = 'YouthSEL'.split('');
  const orgChars = '.org'.split('');

  // Youth character gradients
  const youthGradients = [
    'youth-grad-1', // Y
    'youth-grad-2', // o
    'youth-grad-3', // u
    'youth-grad-4', // t
    'youth-grad-5', // h
    'youth-grad-1', // S
    'youth-grad-2', // E
    'youth-grad-3', // L
  ];

  // Org character gradients
  const orgGradients = [
    'org-grad-1', // .
    'org-grad-2', // o
    'org-grad-3', // r
    'org-grad-4', // g
  ];

  return (
    <div className="inline-flex items-center h-10 md:h-14 gap-1">
      {/* YouthSEL part with enhanced styling and bubbling animation */}
      <div className="bubbling-container">
        {youthselChars.map((char, index) => (
          <span
            key={`youth-${index}`}
            className={`bubbling-char text-2xl md:text-3xl font-black tracking-tight ${
              bubbleState === 'out' ? 'scale-down' : ''
            } ${youthGradients[index]}`}
            style={{
              transitionDelay: `${index * 0.1}s`,
            }}
          >
            {char}
          </span>
        ))}
      </div>

      {/* EMPOWER part - unchanged */}
      <div className="flex overflow-hidden">
        {['E', 'M', 'P', 'O', 'W', 'E', 'R'].map((letter, index) => (
          <span
            key={index}
            className={`inline-block text-2xl md:text-3xl font-bold ${letterStyles[index]}
              ${animate ? 'animate-wiggle opacity-100' : 'opacity-0'}
              hover:scale-110 transition-all duration-300`}
            style={{
              animationDelay: `${index * 0.1}s`,
              animationFillMode: 'forwards',
              textShadow: '3px 3px 6px rgba(0,0,0,0.2)',
            }}
          >
            {letter}
          </span>
        ))}
      </div>

      {/* .org part with enhanced styling and bubbling animation */}
      <div className="bubbling-container">
        {orgChars.map((char, index) => (
          <span
            key={`org-${index}`}
            className={`bubbling-char text-2xl md:text-3xl font-black tracking-tight ${
              bubbleState === 'out' ? 'scale-down' : ''
            } ${orgGradients[index]}`}
            style={{
              transitionDelay: `${index * 0.1}s`,
            }}
          >
            {char}
          </span>
        ))}
      </div>
    </div>
  );
};

// Enhanced animations and styles
const style = document.createElement('style');
style.textContent = `
  @keyframes wiggle {
    0%, 100% {
      transform: rotate(0deg) scale(1);
    }
    25% {
      transform: rotate(10deg) scale(1.1);
    }
    75% {
      transform: rotate(-10deg) scale(1.1);
    }
  }

  .animate-wiggle {
    animation: wiggle 1.2s ease-in-out infinite;
  }

  /* Bubbling animation styles */
  .bubbling-container {
    display: inline-flex;
  }

  .bubbling-char {
    display: inline-block;
    transform-origin: 50% 85%;
    transition: transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: scale(1);
    text-shadow: 2px 2px 4px rgba(0,0,0,0.15);
  }

  .bubbling-char.scale-down {
    transform: scale(0.1);
  }

  /* YouthSEL gradients */
  .youth-grad-1 {
    background: linear-gradient(45deg, #3498db, #2ecc71);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.15));
  }

  .youth-grad-2 {
    background: linear-gradient(45deg, #2ecc71, #f1c40f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.15));
  }

  .youth-grad-3 {
    background: linear-gradient(45deg, #f1c40f, #e74c3c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.15));
  }

  .youth-grad-4 {
    background: linear-gradient(45deg, #e74c3c, #9b59b6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.15));
  }

  .youth-grad-5 {
    background: linear-gradient(45deg, #9b59b6, #3498db);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.15));
  }

  /* .org gradients */
  .org-grad-1 {
    background: linear-gradient(45deg, #00a8ff, #0097e6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.15));
  }

  .org-grad-2 {
    background: linear-gradient(45deg, #9c88ff, #8c7ae6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.15));
  }

  .org-grad-3 {
    background: linear-gradient(45deg, #fbc531, #e1b12c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.15));
  }

  .org-grad-4 {
    background: linear-gradient(45deg, #4cd137, #44bd32);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.15));
  }
`;
document.head.appendChild(style);

const Header = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const { query, results, handleSearch, clearSearch } = useSearch();

  return (
    <header className="bg-white">
      <div className="flex flex-col px-4 py-3 md:px-8 md:py-4">
        {/* Top Section */}
        <div className="flex justify-between items-center">
          {/* Logo */}
          <Link to="/" className="hover:opacity-90 transition-opacity">
            <AnimatedLogo />
          </Link>

          {/* Desktop Search and Login */}
          <div className="hidden md:flex items-center gap-8">
            <div className="relative">
              <input
                type="search"
                placeholder="Search..."
                value={query}
                onChange={(e) => handleSearch(e.target.value)}
                className="w-64 px-3 py-2 border border-gray-300 rounded-md pr-8 text-sm font-nunito"
              />
              <Search 
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2 text-gray-400" 
                size={18}
              />
              {query && (
                <ul className="absolute mt-2 bg-white border border-gray-300 rounded-md w-full shadow-lg max-h-60 overflow-y-auto z-10">
                  {results.length > 0 ? (
                    results.map((result, index) => (
                      <li key={index} className="px-4 py-2 hover:bg-gray-100">
                        <Link to={result.path} onClick={clearSearch}>
                          <strong>{result.title}</strong>
                          <br />
                          <span className="text-sm text-gray-600">{result.description}</span>
                        </Link>
                      </li>
                    ))
                  ) : (
                    <li className="px-4 py-2 text-gray-500">No results found</li>
                  )}
                </ul>
              )}
            </div>
          </div>

          {/* Mobile Controls */}
          <div className="flex md:hidden items-center gap-4">
            <button
              onClick={() => setIsSearchVisible(!isSearchVisible)}
              className="p-2"
            >
              <Search size={20} className="text-gray-600" />
            </button>
            <button 
              onClick={() => setIsLoginOpen(true)}
              className="p-2"
            >
              <UserCircle size={20} className="text-[#4B0082]" />
            </button>
          </div>
        </div>

        {/* Mobile Search Bar */}
        {isSearchVisible && (
          <div className="mt-3 md:hidden">
            <div className="relative">
              <input
                type="search"
                placeholder="Search..."
                value={query}
                onChange={(e) => handleSearch(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md pr-8 text-sm font-nunito"
              />
              <Search 
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2 text-gray-400" 
                size={18}
              />
              {query && (
                <ul className="absolute mt-2 bg-white border border-gray-300 rounded-md w-full shadow-lg max-h-60 overflow-y-auto z-10">
                  {results.length > 0 ? (
                    results.map((result, index) => (
                      <li key={index} className="px-4 py-2 hover:bg-gray-100">
                        <Link to={result.path} onClick={clearSearch}>
                          <strong>{result.title}</strong>
                          <br />
                          <span className="text-sm text-gray-600">{result.description}</span>
                        </Link>
                      </li>
                    ))
                  ) : (
                    <li className="px-4 py-2 text-gray-500">No results found</li>
                  )}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>

      <LoginModal 
        isOpen={isLoginOpen} 
        onClose={() => setIsLoginOpen(false)} 
      />
    </header>
  );
};

export default Header;