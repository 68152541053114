import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children, moduleId }) => {
  const { unlockedModules } = useAuth();

  if (moduleId && !unlockedModules[moduleId]) {
    return <Navigate to="/online-classes" replace />;
  }

  return children;
};

export default ProtectedRoute;